<template>
	<div>
	<ContentHeader title="Data Jurnal Keuangan"  />
	<LoadingScreen v-if="loading_screen" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Jurnal Keuangan</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-data-jurnal-keuangan"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="changePage">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Awal</span>
	            					<input type="date" class="form-control" v-model="periode_awal">
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Akhir</span>
	            					<input type="date" class="form-control" v-model="periode_akhir">
	            				</div>
	            				<div class="col-lg-1 pt-4">
	            					<button class="btn btn-primary" @click="getDataResultByPeriode">Filter</button>
	            				</div>				
	            				<div class="col-lg-5"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>
							      <th scope="col" class="text-sm">TANGGAL</th>
							      <th scope="col" class="text-sm">NOMOR BUKTI</th>
							      <th scope="col" class="text-sm">JURNAL SINGKAT</th>
							      <th scope="col" class="text-sm">AKUN DEBIT</th>
							      <th scope="col" class="text-sm">AKUN KREDIT</th>
							      <th scope="col" class="text-sm">NOMINAL</th>
							      <th scope="col" class="text-sm">KETERANGAN</th>
							    </tr>
							  </thead>
							  <tbody>
							  	<tr v-for="(row, index) in data_result" :key="row.id">
							    	<td>{{ index+1 }}</td>
							    	<td>{{ format_tanggal(row.tanggal) }}</td>
							    	<td>{{ row.nomor_bukti }}</td>
							    	<td>{{ row.jurnal_singkat.jenis }}</td>
							    	<td>{{ row.akun_debit.kode }} - {{ row.akun_debit.nama }}</td>
							    	<td>{{ row.akun_kredit.kode }} - {{ row.akun_kredit.nama }}</td>
							    	<td>{{ format_nominal(row.nominal) }}</td>
							    	<td>{{ row.keterangan }}</td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_tanggal from '@/format_tanggal.js'
import format_nominal from '@/format_nominal.js'
import LoadingScreen from '@/components/LoadingScreen'

export default{
	components: {
		ContentHeader: ContentHeader,
		LoadingScreen: LoadingScreen,
	},
    setup(){
    	const user = computed(() => store.getters['auth/user'])
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])

		const loading_screen = ref(false)
		const periode_awal = ref('')
		const periode_akhir = ref('')

		const getDataResult = async () => {
			data_result.value = []
	    	memuat_data.value = true
	    	loading_screen.value = true
	    	cek_data.value = false


			let { data } = await axios.get(`api/jurnal_keuangan/get/${user.value.cabang_id}/${page.value}`)
console.log(data)
			if (data != 'kosong') {
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
	    		loading_screen.value = false
			} else {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
				loading_screen.value = false
			}
		}

		const getDataResultByPeriode = async () => {
			if (periode_awal.value != '' && periode_akhir.value != '') {
				data_result.value = []
		    	memuat_data.value = true
		    	cek_data.value = false
		    	loading_screen.value = true

				let { data } = await axios.get(`api/jurnal_keuangan/getByPeriode/${user.value.cabang_id}/${page.value}/${periode_awal.value}/${periode_akhir.value}`)
				console.log(data)
				if (data != 'kosong') {
					memuat_data.value = false
					cek_data.value = false
					data_result.value = data.data
					links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    			loading_screen.value = false
				} else {
					cek_data.value = true
					memuat_data.value = false
					data_result.value = []
	    			loading_screen.value = false
				}	
			}
			
		}

		const changePage = () => {
			if (periode_awal.value != '' && periode_akhir.value != '') {
				getDataResultByPeriode()
			} else {
				getDataResult()
			}
		}

		const paginateData = async (url) => {

    		if (url != null) {
    			data_result.value = []
	    		memuat_data.value = true

	    		let link_url = new URL(url)
	    		link_url = `${link_url.pathname}${link_url.search}`
	    		
	    		let { data } = await axios.get(`${link_url}`)

	    		
	    		if (data != 'kosong') {
	    			memuat_data.value = false
	    			data_result.value = data.data	
	    			links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    		}
    		}
    	}

		onMounted(() => {
			getDataResult()
		})

		return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, format_tanggal, format_nominal, periode_awal, periode_akhir, changePage, loading_screen, getDataResultByPeriode }
    }
}
</script>

<style>
	
</style>