function format_tanggal(date){
	if (date == null) {
		return '-'
	} else {
		let hari = date.slice(8, 10)
		let bulan = date.slice(5, 7)
		let tahun = date.slice(0, 4)

		if(bulan == '01'){
			bulan = 'Januari'
		} else if (bulan == '02') {
			bulan = 'Februari'
		} else if (bulan == '03') {
			bulan = 'Maret'
		} else if (bulan == '04') {
			bulan = 'April'
		} else if (bulan == '05') {
			bulan = 'Mei'
		} else if (bulan == '06') {
			bulan = 'Juni'
		} else if (bulan == '07') {
			bulan = 'Juli'
		} else if (bulan == '08') {
			bulan = 'Agustus'
		} else if (bulan == '09') {
			bulan = 'September'
		} else if (bulan == '10') {
			bulan = 'Oktober'
		} else if (bulan == '11') {
			bulan = 'November' 
		} else if (bulan == '12') {
			bulan = 'Desember'
		}
		
		let tanggal = `${hari} ${bulan} ${tahun}`
		
		return tanggal

	}
}

export default format_tanggal